@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=PT+Sans:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "Varela Round";
  background-color: white;
  color: #636161;
}

.dFlex, nav, nav ul, .main_content, .main_content .custom-card, .main_content .custom-card .custom-card_header, .custom-btn, .footer, .footer .social, .footer .social .fa {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 50px 20px 50px;
  box-sizing: border-box;
  background-color: #272b34;
}

nav {
  justify-content: space-between;
  width: 100%;
  color: #fff;
}

nav .logo {
  font-size: 30px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

nav ul li {
  font-size: 14px;
  margin-left: 15px;
  /* padding: 10px; */
  box-sizing: border-box;
  color: #727272;
  cursor: pointer;
}
nav ul li a{
  text-decoration: none;
  color: #727272;
  cursor: pointer;
}

nav .search {
  color: rgba(255, 255, 255, 0.7);
}

nav .search .fa {
  cursor: pointer;
}

nav .search .fa + .fa {
  margin-left: 15px;
}

.common-logo img {
  max-width: 200px;
  max-height: 200px;
}

.main_content {
  /* position: relative; */
  /* width: 70%;
  height: 100%; */
  /* margin: 4.5rem auto; */
  /* padding: 50px 0 0; */
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
      min-height: calc(100vh - 138px);
}

.main_content h3 {
  position: absolute;
  left: 0;
  top: 0;
  color: rgba(0, 0, 0, 0.05);
  font-size: 10.5vw;
}

.main_content .custom-card {
  /* width: 20%; */
  border-radius: 15px;
  flex-direction: column;
  position: relative;
  /* -webkit-transform: scale(0.8);
          transform: scale(0.8); */
  transition: all .3s ease-in-out;
  /* margin-bottom: 50px; */
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.main_content .custom-card .custom-card_img {
  /* margin-top: -5vw; */ height:200px;
}

.main_content .custom-card .custom-card_img img {
  max-width: 200px;
  max-height: 200px;
}

.main_content .custom-card .custom-card_header {
  flex-direction: column;
  color: black;
}

.main_content .custom-card .custom-card_header h2 {
  font-size: 18px;
}

.main_content .custom-card .custom-card_header p {
  font-size: 14px;
  text-align: center;
  color: rgb(4 3 3 / 50%);
  margin: 8px 0;
}

.main_content .custom-card .custom-card_header .price {
  font-size: 25px;
  color: #fe8033;
}

.main_content .custom-card .custom-card_header .price span {
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
}

.main_content .custom-card:hover {
  /* background-color: #2b3039;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); */
  cursor: pointer;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
}

.main_content .custom-card:hover .custom-btn {
  background-color: #0a4476;
  color: #fff;
}

.custom-btn {
  width: 130px;
  height: 35px;
  font-size: 14px;
  border-radius: 35px;
  background-color: #0a4476;
  color: #fff;
  cursor: pointer;
  position: relative;
  transition: background-color .3s ease;
  overflow: hidden;
  border: none;
}

.custom-btn a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}
.custom-btn a:hover {
  text-decoration: none;
  color: white;
}

.footer {
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  font-size: 12px;
  background-color: #f2f2f2;
  color: #0a4476;
  padding: 10px;
  z-index: 100;
}

.footer .social .fa {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid black;
  font-size: 14px;
  color: black;
  cursor: pointer;
}
.footer p {
  margin: 0;
}

.footer .social .fa + .fa {
  margin-left: 15px;
}
.tran-bg
{
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: white;
}

.tran-bg small{
  color: white !important;
}

.tran-bg .custom-form{
  padding: 50px 50px;
}
.login-form{
  width: 50%;
}

.zindex{
  z-index: 1;
}
.loader_div
{
  /* position: fixed;
  top: 36%;
  left: 44%; */
  z-index: 1000;
  position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transform: -webkit-translate(-50%, -50%);
            transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
  padding: 20px 70px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%)
}
.cart-icon{
  position:relative;
  z-index: 1000;
}

.cart-count
{
  background-color: black;
  font-size: 10px;
  color: white;
  padding: 3px 5px;
  position: absolute;
  top: -10px;
  right: -7px;
  border-radius: 50%;
  z-index: -1;;
}
.single-product
{
  background-color: white;
  color: white;
  padding: 30px 15px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
    color: #636161;
    font-size: 14px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
}
.single-product:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);

}
.h-tag{
  color: #636161;
}
.search a{
  text-decoration: none;
  color: white;
}
.cart-product-close
{
  position: absolute;
  top: 0px;
  right: 5px;
}
.amount span{
  color: white;
  padding: 0 20px;
}
.count 
{
  width: 40px;
  height: 30px;
  border: 1px solid #555;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: white;
}

/* Toastr */
.warning-toast, .success-toast, .error-toast {
  background-color: #51A351 !important;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
}
.Toastify__progress-bar {
  background: transparent;
}

/* @media screen and (max-width:1399px)
{
  .main_content{
    width: 90%;
  }
} */
.cart-table tr td{
  vertical-align: middle;
  text-align: center;
}
.cart-table .price {
  /* font-size: 16px; */
  color: #fe8033;
}
.price {
  color: #fe8033;
}
.checkout-btn{
  background: #3a6991;
  outline: none;
  border: none;
  border-radius: 0px;
}
.custom-anchor-tag
{
  text-decoration: none;
  color:black;
}
.custom-anchor-tag:hover{
  text-decoration: none;
  color: white;
}
.checkout-section{
  background-color: rgba(0, 0, 0, 0.05);
  color:white;
}
.billing_details{
  padding: 25px 0px;
  width: 80%;
}
.checkout-items p {
  margin: 0;
  font-size: 12px;
  padding: 2px;
}
.checkout-products{
  margin-top: 20px;
}
.home-banner{
  background-image: url(/static/media/banner.0fb5380b.jpg);
}
.about-us
{
  background-image: url(/static/media/about-us.90b22d01.jpg);
}
.contact-us{
  background-image: url(/static/media/contact-bg.c94df486.jpg);
}
.remove-item
{
  cursor: pointer;
}
.remove-item:hover{
  color: red;
}

.remove-item > .fa{
  font-size: 18px;
}
.cart-btn{
  font-size: 12px;
  border-radius: 0;
}
.checkout-btn:disabled{
  background-color: #fe8033;
}

.send-po{
  padding: 0 15px;
}
.clear-filter{
  background: transparent;
  border: 1px solid #ccc;
  padding: 0px;
  font-size: 12px;
  padding: .320rem .75rem;
  color: #636161;
}
.filters-form
{
  font-size: 14px;
}
.form-control
{
  font-size: 14px;
}
.common-logo
{
  margin-top: 60px;
  /* background-color: rgb(132 126 126); */
  /* padding: 15px 0px; */
  text-align: center;
}
/* .header-shadow{
  box-shadow: 0px 2px 5px rgba(0,0,0,0.5);
  z-index: 1000;
  background-color: rgb(132 126 126);
  transition: 0.5s all;
} */
.header-shadow nav ul li a {
  color: #808285!important;
  text-transform: uppercase;
}
.footer-spon
{
  letter-spacing: 3px;
}
.item_link
{
  text-decoration: none;
  color: rgb(4 3 3 / 50%);
}
.item_link:hover{
  text-decoration: none;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  border: solid 1px #161b4a;
  padding: 7px;
  border-radius: 11px;
  width: 82px; height: 82px;
  margin-bottom: 10px !important;
  margin-top: 0px;
}

.nav-tabs .nav-item a {
  border: none !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px !important;
}



@media only screen and (max-width: 1199px) {
  .main_content .custom-card .custom-card_img img {
    max-width: 160px;
    max-height: 160px;
}
.main_content .custom-card .custom-card_img {
  height: 160px;
}
}

/* @media only screen and (max-width: 991px) and (min-width: 768px){
  .main_content .custom-card .custom-card_img img {
    max-width: 160px;
    max-height: 160px;
}
.main_content .custom-card .custom-card_img {

  height: 200px;
}
} */
.form-field-col label {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

@media only screen and (max-width: 992px) {
.nav-tabs .nav-item {
  padding: 3px;
  border-radius: 5px;
  width: 52px;
  height: 52px;
}
.detail-thumbnail-list {
  max-width: 70px;
}

}

@media only screen and (max-width: 767px){
  .container {
  max-width: 100%;
}
  .login-form{
    width: 100%;
  }
  .tran-bg .custom-form{
    padding: 30px 20px;
  }

  .navbar-toggler { border-color:#ccc;padding: 0;    font-size: 1rem;}
  .navbar-toggler-icon {
    width: 2.1rem;
    height: auto;
    line-height: 2rem;
}
.main_content {
  margin: 2.5rem auto;
}

.header .navbar-nav li {
    margin: 0 !important;
}
nav ul li {
  margin-left: 0 !important;
}

.header .navbar-nav li .nav-link {
  padding: 0.3rem 1rem;
}
.header .login {padding-top: 0.3rem}


/* .header .navbar-nav {
  border-top: 1px solid #eaeaea;
} */
.login ul{
  flex-direction: column;
}
.login >ul >li{
  margin-top: 5px;
}

.filters-form .form-control {
  height: 2rem !important;
}
.form-field-col label {
  padding-bottom: 0px !important; margin-bottom: 3px;
}
.toggle-bar { width: 100%;}
.mob-logo img{
  max-height: 60px;

}
}

@media only screen and (max-width: 570px){
  .main_content .custom-card .custom-card_img {
    height: auto;
  }

}
@media only screen and (max-width: 479px){ 
  .filters-form .form-control {
    height: 2.5rem !important;
}
.navbar-toggler { border-color:#ccc;padding: 0;    font-size: 1.3rem;}
.navbar-toggler-icon {
  width: 2.75rem;
  line-height: 2.5rem;
}
}
@media only screen and (max-width: 380px){ 
  .filters-form .form-control {
    height: 3rem !important;
}
}
.box-anchor
{
  text-decoration: none;
}
.box-anchor:hover
{
  text-decoration: none;
 
}
.back-btn > button:hover{
  color:white !important;
}
.disabled-color{
  color: #c1bdbd;
}
.search-results
{
  font-size: 14px;
}
.pagination
{
  width: 35%;
  margin: auto;
  margin-bottom: 4rem;
}
.page-link
{
  color: rgb(132 126 126) !important;
}
.page-item.active .page-link{
  z-index: 1;
  color: #fff !important;
  background-color: rgb(132 126 126) !important;
  border-color: rgb(132 126 126) !important;
}
iframe{
  border: none;
}
iframe{
  width: 400px;
  height: 400px;
}
.gem-images{
  max-height: 400px;
}
@media only screen and (min-width: 1200px)
{

}

@media only screen and (max-width: 1199px)
{
  iframe{
    width: 250px;
    height: 250px;
  }
  .gem-images{
    /* width: 250px; */
    max-height: 250px;
  }
}
@media only screen and (max-width: 767px){
  .nav-tabs .nav-item {
    padding: 1vw;
    border-radius: 1vw;
    width: 62px;
    width: 12.5vw;
    margin-bottom: 6px !important;
    margin-top: 0px;
    height: 62px;
    height: 12.5vw;
    margin-right: 1vw !important;
}
/* .nav-tabs .nav-item a {
  width: 50px;
  height: 50px;
} */

}

@media only screen and (max-width: 575px)
{

}

@media only screen and (max-width: 414px)
{
  iframe {
    width: 250px;
    height: 250px;
  }
}



/*# sourceMappingURL=App.css.map */







html {}
:root {
  --themeColor: #727272;
  --themeColor2: #c83339;
}
/*html { font-size: 15px;}*/
/* body {color: var(--themeColor);
  font-family: 'Montserrat', sans-serif; 
 }
a, .btn, input, input[type="search"], input[type="submit"]{ webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -ms-transition: all 0.2s linear; -o-transition: all 0.2s linear; transition: all 0.2s linear; }
a { color: inherit; }
li.active a, a:hover, a:focus {text-decoration: none;color: #ef004b;outline: none;}
.btn, form input[type="submit"] {padding: 0.3rem 2.77rem;min-height: 3.33rem;color: #fff;font-size: 1.33rem;box-shadow: none;text-transform: uppercase;outline: none;border-color: transparent;font-weight: 600;background: var(--themeColor1);display: inline-flex;align-items: center;justify-content: center;}
.btn:hover, .btn:focus, form input[type="submit"]:hover, form input[type="submit"]:focus {background: var(--themeColor2);color: #fff;border-color: transparent !important;} */
/*.color1 { color: var(--themeColor1); }
.color2 { color: var(--themeColor2); }
.color3 { color: var(--themeColor3); }
.color1-hover:hover { color: var(--themeColor2); }
.color2-hover:hover { color: var(--themeColor1); }*/
.bg-cover {background-repeat: no-repeat; background-size:cover;}
.border {
  border-color:  #e1e1e1!important;
}
a {
    color: #fff;
}
a:hover {
    color: #000;
}
.header {
    background: #fff;
    border-bottom: 1px solid #dee2e6;
}
.header.header-shadow {
    z-index: 1000;
    background-color: #FFF;
}
.header-shadow nav ul li a {
    color: #808285!important;
    text-transform: uppercase;
}
.navbar {
    padding: .7rem 1rem;
}
.menuCenter li:first-child {
    margin-left: 3rem;
}
.mainWrapper {
    margin-top: 6rem;
}
.mainWrapper.noData {
    height: 120vh;
}
.custom-btn {
    background-color: #0a4476 !important;
    color: #fff !important;
}
.custom-btn :hover {
    background-color: #3a6991 !important;
}
.checkout-btn {
    background: #0a4476 !important;
}
.main_content .custom-card:hover .custom-btn {
    background-color: #3a6991 !important;
}
.checkout-btn:hover {
    background-color: #3a6991 !important;
}

@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1170px;
}
}
@media(min-width:1620px) {
  html { font-size: 18px; }
  .container {     max-width: 1400px;}
}
.header  {right: 0px;left: 0px;top:0px;z-index: 10;}
.header .navbar-nav li {margin-right: 3rem;}
.header .navbar-nav li a { color: #727272; text-transform: uppercase; }
.header .navbar-nav li a:hover { text-decoration: none; }

.login a { color: #727272;  }
.header-active .login a { color: #727272;  }
.home-logo img {max-width: 240px;}
.home-stone img, .about-stone img {max-width: 180px;}

.banner.bg-cover {
    min-height: 60vh;
    background-repeat: no-repeat;
    background-size: 100%;
}
.company-tag-section{
    background: #a6a6a6;
}
.ct-line-col h1 {
    font-size: 4.15rem;
}

.shopnow-col a {
    font-size: 2rem;
    line-height: 1.3;
}

.about-us-section .bg-cover {
    min-height: 60vh;
    background-size: 100%;
}



.selection-col {height: 36rem;}
.selection-col h3 {
    /* background-color: #fff5ee; */
    -webkit-transform: rotate(
-180deg
);
            transform: rotate(
-180deg
);
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: sideways-right;
            text-orientation: sideways-right;
    font-size: 3.5rem;
    line-height: 1;
    top: 0px;
    bottom: 0px;
    z-index: 20;
    text-align: center;
    color: #727272;
}

.selection-col:after {content:'';position: absolute;background: #fff5ee;/* height: 100%; *//* width: 100%; */top: 0px;left: 15px;bottom: 0px;right: 15px;}



.selection-prod-col img {max-width: 10rem;}
.contact-us {
    min-height: calc(100vh - 38px);
}
.contact-logo {max-width: 300px;}

.contact-us-col h3 {font-size: 3.2rem;}
.contact-us-col p {
    font-size: 1.34rem;
    font-weight: 500;
}

/* Toastr */
.warning-toast, .success-toast, .error-toast {
    background-color: #51A351 !important;
    color: #fff !important;
}
.Toastify__progress-bar {
    background: transparent;
}

/* captcha */
#reload_href {
    color: #fff !important;
}

.footer {
    background-color: #f2f2f2;
    color: #0a4476;
    position: fixed;
    bottom: 0;

}

/* Second version */
.sec-1 .sec-1-title{
    font-size: 5rem;
    border-bottom: 2px solid #636161;
} 
.sec-1{
    margin-top: 56px;
    padding: 6rem 0px;
    min-height: calc(100vh - 94px);
}

.sec-2{
    margin-top: 56px;
    padding: 6rem 0px;
    min-height: calc(100vh - 94px);
}
.v2-img{
    width: 75%;
  }
  @media(max-width: 800px){

    html{
      font-size: 3vmin;
    }
    h2{
      font-size: 1.6rem;
    }
    .v2-img{
    width:100%;
  }
  p{
    font-size: .9rem;
  }
  .sec-1 .sec-1-title {
      font-size: 4rem;
  }
  .sec-1 h3{
    font-size: 1.4rem;
  }
  }
  @media(max-width: 576px){
    
    .login a, header .navbar-toggler i {
    color: #727272;
}
.header-active .navbar-toggler i{
    color: #fff;
}
}

.our-selection {
    padding: 2rem 0 5rem;
}
.our-selection .btn {
    background-color: #0a4476;
    color: #fff !important;
    border-radius: 30px;
    font-size: 18px;
    padding: 10px 20px;
    min-height: auto;
}
.our-selection .title {
    padding: 3rem 0 4rem;
}


/* Theme three css */
.v3-sec-1 h1{
  font-size: 3.7rem;
}
.v3-sec-2{
  background-image: url(/static/media/sec-2-bg.b7c75178.png);
  background-position: top center;
  background-size: cover;
  padding: 6rem 0rem;
  min-height: calc(100vh - 224px);
}
.v3-sec-3 h1{
  font-size: 4.5rem;
}
.v3-bg-stone-3{
  background-image: url(/static/media/bg-stone-3.bbca2f2d.jpg);
  background-size: cover;
  background-position: center center;
}
.bg-stone-1
{
 background-image: url(/static/media/bg-stone-1.3c922f82.jpg);
width:100%;
}
.bg-theme{
    background-color: #282828;
}
.v3-sec-4 .bg-theme-2 img, .v3-bg-stone-3 img{
  padding: 4rem 0rem;
}
.fs
{
  font-size: 14px;

}
.v3-img-1{
margin-top: 200px;
    margin-bottom: 123px;
    padding: 9px;
  }
@media(max-width: 576px){
.v3-sec-5 .v3-bg-stone-1 {
    background-size: cover;
    padding: 1rem 4rem;
    background-position: center center;
    min-height: 276px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.v3-sec-5 .v3-img-1
{
  margin: 0px;
}
.v3-sec-4 .bg-theme-2 img, .v3-bg-stone-3 img {
    padding: 2rem 0rem;
}
}
@media(max-width: 400px){
  .v3-sec-4 .v3-bg-theme-2 img, .v3-bg-stone-3 img {
    padding: 0rem 0rem;
}

    
}
/* Theme four css */
.v4-text-color{
    color: #090991;
}

.v4-banner{
    min-height: 220px;
}
.banner-1
{
  background-position: center;
  width:100%;
}
.v4-sec-1{
    padding-top:8rem;
    padding-bottom:5rem; 
}
.v4-contact-us{
    padding: 8rem 0rem;
}
.stone-absolute {
    top: calc(50% - 46.5px);
    left: calc(30% - 100px);
}
.v4-img
{
    width: 100%;
}

@media(max-width: 992px){
    html{
        font-size: 2vmin;
    }
    .stone-absolute {
    top: calc(50% - 40.5px);
    left: calc(30% - 80px);
    width: 80px;
    height: auto;
}
.v4-heading{
    font-size: 2rem;
}
.v4-para{
    font-size: .9rem;
}
.v4-det-1 h5{
    font-size: 1.2rem;
}
.v4-det-1 p{
    font-size: .8rem;
}
}

@media(max-width: 767px){
    html{
        font-size: 3.4vmin;
    }
    .header {
        height: auto;
        background-color: #fff;
        border-bottom: 1px solid  #eaeaea;
    }
    .menuCenter {
        margin-top: 1rem;
    }

    .mainWrapper {
        margin-top: 4.8rem;
    }

    .our-selection .row.w-100 {
        width: auto !important;
    }
    .selection-prod-col {
        max-width: 33.3%;
    }
    .selection-prod-col img {
        max-width: 100%;
    }
    .banner.bg-cover, .about-us-section .bg-cover {
        min-height: 30vh;
        /* background-size: cover; */
        background-position: center center;
    }
}
@media(max-width: 575px){
    .custom-row{
        flex-direction: column-reverse;
    }
}

@media(max-width: 575px){
.banner.bg-cover {
    min-height: 25vh;
}
.about-us-section .bg-cover {
    min-height: 25vh;
}
}
.stone-img-box{
    height: 71px;
    width: 65px;
}
.nav.nav-tabs .nav-link{
    border:1px solid #dadada;
}
.nav.nav-tabs .nav-link:hover{
    border:1px solid #000;
}

.nav.nav-tabs .nav-link.active{
    border:1px solid #000;
}
.stone-img-box .nav-link{
    color: #000;
}
/* 
.video-section{
    height: 400px;
    width: 400px;
} 
*/
.video-section video { width: 100%;
    height: 100%;}
.stone-descrip-div:nth-child(even){
    background-color: #f1f1f1;
}
.detail-thumbnail-list  { max-width: 90px;}

@media(max-width: 991px){
    .detail-thumbnail-list  { max-width: 60px;}
}
@media(max-width: 575px){
    .stone-img-box {
width: 60px;
}
}
@media(max-width: 767px){
    .gemstone-detail-section .no-border{
        border:none !important;
    }
    .detail-thumbnail-list  { max-width: 100%;}
}

.detail-sec-1
{
    font-family: 'Nunito Sans', sans-serif;
    /* background-color: rgba(0,0,0,0.05); */
    /* border-radius: 25px; */
    color: black;
    font-size: 14px;
}
.gemstone-detail{
    /* border: 1px solid #ccc; */
    /* background-color: #eee; */
    padding: 5px;
}
.gemstone-detail-table{
    width: 100%;
}
.gemstone-detail-table tr td
{
    padding: 5px 15px 5px 0px;
    font-weight: 600;   
}

.custom-btn{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}
.custom-btn:hover{
    color: white;
}
